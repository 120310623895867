import React from 'react'
import TagUI from './Tag.ui'

type TagProps = {
  title: string
  onClose: () => void
}

const Tag = ({ title, onClose }: TagProps) => {
  const titleTruncated = title.length > 25 ? `${title.slice(0, 25)}...` : title
  return <TagUI onClose={onClose} title={titleTruncated} />
}

export default Tag
