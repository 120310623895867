import { Button } from '@butcherbox/freezer'
import React from 'react'
import Tag from '../Tag/Tag'
import * as Styles from './TagList.css'
import { TagListUIProps } from './TagList.types'

const TagListUI = ({
  filterCutsMap,
  handleTagClick,
  handleClearAllClick,
  selectedItems,
}: TagListUIProps) => {
  return (
    <>
      <ul className={Styles.TagList}>
        {selectedItems.map((cutId) => {
          if (!filterCutsMap[cutId]) return null
          return (
            <li key={cutId} title={`${filterCutsMap[cutId]} filter`}>
              <Tag
                onClose={() => handleTagClick(cutId)}
                title={filterCutsMap[cutId]}
              />
            </li>
          )
        })}
        <li className={Styles.ClearBtn}>
          {selectedItems.length > 0 && (
            <Button onClick={handleClearAllClick} variant="text">
              Clear All
            </Button>
          )}
        </li>
      </ul>
    </>
  )
}

export default TagListUI
