import { JustCookSkuAndTags } from '~/data/justcook-tags-data'

export const trackFilteredRecipes = (cutList: number[]) => {
  const descriptions = []

  JustCookSkuAndTags.forEach((item) => {
    if (cutList.includes(item.sku)) {
      descriptions.push(item.description)
    }
  })

  window.analytics?.track('Recipes Filtered', {
    cut_list: descriptions,
  })
}
