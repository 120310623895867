import { RouteComponentProps } from '@reach/router'
import { difference } from 'lodash'
import React from 'react'
import { trackFilteredRecipes } from './trackFilteredRecipes'
import { JustCookRecipe } from '~/bb-api/schemata'
import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'
import useJustCookSearch from '~/hooks/useJustCookSearch'
import AccountBrowseRecipesUI from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.ui'
import useBrowseRecipesFilterState from './useBrowseRecipesFilterState'
import useCutsFromNextBoxGroup from '~/routes/AccountBrowseRecipes/useCutsFromNextBoxGroup'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { JustCookSkuToTagIdMap } from '~/routes/AccountBrowseRecipes/proteinAndCutsData'

const RECIPES_TO_SHOW = 6
const FOOTER_HEIGHT = 3000

export const AccountBrowseRecipes = () => {
  const [showNumberOfRecipes, setShowNumberOfRecipes] = React.useState(
    RECIPES_TO_SHOW
  )
  const [page, setPage] = React.useState(1)
  const [recipesFetched, setRecipesFetched] = React.useState<JustCookRecipe[]>(
    []
  )

  const {
    cutsGroups,
    selectedCutIds,
    setSelectedCutIds,
    uniqueSelections,
    tagSelections,
  } = useBrowseRecipesFilterState()

  const selectedTagIds = React.useMemo(
    () => uniqueSelections.cuts.map((sku) => JustCookSkuToTagIdMap[sku].tagId),
    [uniqueSelections]
  )

  const {
    data: searchedTagRecipes,
    status: searchedTagRecipesStatus,
    isLoading,
    isFetching,
  } = useJustCookSearch({
    page,
    categories: uniqueSelections.categories.filter((c) => c !== 'nextBox'),
    tagIds: selectedTagIds,
  })

  React.useEffect(() => {
    if (!searchedTagRecipes) return

    const shouldWeLazyLoad =
      difference(searchedTagRecipes?.posts, recipesFetched).length !== 0

    shouldWeLazyLoad &&
      setRecipesFetched((prevState) => [
        ...(page !== 1 ? prevState : []),
        ...searchedTagRecipes?.posts,
      ])
  }, [page, recipesFetched, searchedTagRecipes])

  React.useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY
      const numberOfRecipesFetched = recipesFetched?.length
      const numberOfRecipesShowed = recipesFetched?.slice(
        0,
        showNumberOfRecipes
      ).length

      const hasMoreRecipesToFetch =
        numberOfRecipesShowed === numberOfRecipesFetched &&
        page < searchedTagRecipes?.totalPages

      if (position >= document.body.scrollHeight - FOOTER_HEIGHT) {
        setShowNumberOfRecipes(showNumberOfRecipes + RECIPES_TO_SHOW)
        if (hasMoreRecipesToFetch && recipesFetched.length >= page * 100) {
          setPage((prevState) => prevState + 1)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [
    page,
    isFetching,
    recipesFetched,
    searchedTagRecipes,
    searchedTagRecipesStatus,
    showNumberOfRecipes,
  ])

  const resetState = () => {
    setPage(1)
    setShowNumberOfRecipes(RECIPES_TO_SHOW)
  }

  const onApplyFilterHandler = (selectedIds: number[]) => {
    setSelectedCutIds(selectedIds)
    resetState()
    trackFilteredRecipes(selectedIds)
  }

  return (
    <AccountBrowseRecipesUI
      cutsGroups={cutsGroups}
      isLoading={isLoading}
      onApplyFilters={onApplyFilterHandler}
      recipes={recipesFetched?.slice(0, showNumberOfRecipes)}
      selectedCutIds={selectedCutIds}
      tagSelections={tagSelections}
      totalRecipeResults={searchedTagRecipes?.totalPosts ?? 0}
    />
  )
}

const WaitForNextBox: React.FC<RouteComponentProps> = () => {
  const nextBoxItems = useCutsFromNextBoxGroup()
  return !nextBoxItems ? <LoadingSpinner /> : <AccountBrowseRecipes />
}

// Wrap this component with an Error Boundary,
// If the API fails, invoke the fallback.
export default withErrorBoundary(WaitForNextBox, {})
