import React from 'react'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import {
  CutId,
  CutsFilterGroup,
  ItemGroup,
  ListItem,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import {
  getUniqueCutsBySlug,
  JustCookSkuToTagIdMap,
} from '~/routes/AccountBrowseRecipes/proteinAndCutsData'
import { JustCookSkuAndTags } from '~/data/justcook-tags-data'
import { consolidateOrderItems } from '~/utils/consolidateOrderItems'

/**
 * Converts an array of any skus and:
 * 1. Finds the corresponding cuts filter items (i.e., by slug)
 * 2. Creates, from these cut filter item skus, UI-ready objects for the CutsFilter component.
 * @param skus
 */
export const createCutFilterItems = (
  skus: Array<number | string>
): ListItem<number>[] => {
  if (!skus) return []

  const boxItemsWithSlug = skus
    .map((sku) => JustCookSkuToTagIdMap[sku])
    .filter(Boolean) // discard any skus not present in our tag map

  const uniqueBoxItems = getUniqueCutsBySlug(boxItemsWithSlug).sort((a, b) =>
    a.description > b.description ? 1 : -1
  )

  return uniqueBoxItems.map((item) => {
    const foundItem = JustCookSkuAndTags.find((cut) => cut.slug === item.slug)

    if (!foundItem) return null

    return {
      label: item.description,
      id: foundItem.sku,
    }
  })
}

export const getFilterItemSkus = (skus: Array<number | string>) =>
  createCutFilterItems(skus).map((item) => item.id)

const useCutsFromNextBoxGroup = () => {
  const { data: orderData } = useUpcomingOrder()

  const { items, itemIds } = React.useMemo(() => {
    const boxItemSkus = consolidateOrderItems(orderData).map((i) => i.sku)
    const items = createCutFilterItems(boxItemSkus)
    return { items, itemIds: items.map((i) => i.id) }
  }, [orderData])

  const nextBoxGroup: ItemGroup<CutsFilterGroup, CutId> = React.useMemo(() => {
    return {
      group: {
        id: 'nextBox',
        label: 'Cuts In Your Next Box',
      },
      items,
      itemIds,
    }
  }, [itemIds, items])
  return orderData ? nextBoxGroup : null
}

export default useCutsFromNextBoxGroup
