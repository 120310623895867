import { useLocation } from '@reach/router'
import React from 'react'
import {
  CutId,
  CutsFilterGroup,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import {
  allUniqueCuts,
  uiCutsGroups as CUTS_GROUPS,
} from '~/routes/AccountBrowseRecipes/proteinAndCutsData'
import useCutsFromNextBoxGroup from '~/routes/AccountBrowseRecipes/useCutsFromNextBoxGroup'
import itemGroupSelectors from '~/routes/AccountBrowseRecipes/itemGroupSelectors'
import { CATEGORY_MAP } from '~/hooks/useJustCookSearch'

const replaceUrl = (newUrl) => window.history.replaceState(null, null, newUrl)

export const buildUrl = (proteinIds: CutsFilterGroup[], skus: CutId[]) => {
  const params = new URLSearchParams()
  proteinIds.forEach((id) => params.append('category', `${id}`))
  skus.forEach((sku) => params.append('sku', `${sku}`))
  const paramsStr = params.toString()
  let url = '/account/recipes'
  if (paramsStr) url += `?${paramsStr}`
  return url
}

const getSkusFromUrlString = (search: string) => {
  const matches = [...search.matchAll(/sku=(\d+)/g)]
  const skus = matches.map((m) => m[1]).map(Number)
  return skus.filter((sku) => allUniqueCuts.find((cut) => cut.sku === sku))
}

const getProteinsFromString = (search: string) => {
  const matches = [...search.matchAll(/category=(\w+)/g)]
  return matches
    .map((m) => m[1])
    .filter((str) => CATEGORY_MAP[str]) as CutsFilterGroup[]
}

// must be defined outside the hook (or memoized) or else it will cause
// almost every relevant effect to rerun on every render.
const summaryGroups: CutsFilterGroup[] = ['nextBox']

const useBrowseRecipesFilterState = () => {
  const nextBoxItems = useCutsFromNextBoxGroup()
  const cutsGroups = React.useMemo(() => [nextBoxItems, ...CUTS_GROUPS], [
    nextBoxItems,
  ])

  const {
    getGroupsAndItemsFromSelections,
    getSelectionsFromGroupsAndItems,
  } = React.useMemo(() => itemGroupSelectors(cutsGroups, summaryGroups), [
    cutsGroups,
  ])

  // parse url on load
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const proteinsFromUrl = getProteinsFromString(search)
  const cutsFromUrl = getSkusFromUrlString(search)
  const isItNextBox = !!params.get('nextBox')

  const selectionsFromUrl = React.useMemo(
    () =>
      getSelectionsFromGroupsAndItems({
        groupIds: proteinsFromUrl,
        itemIds: cutsFromUrl,
      }),
    [cutsFromUrl, getSelectionsFromGroupsAndItems, proteinsFromUrl]
  )

  const initialSelectedCuts = !isItNextBox
    ? selectionsFromUrl
    : nextBoxItems?.itemIds.concat(selectionsFromUrl)

  const [selectedCutIds, setSelectedCutIds] = React.useState([
    ...new Set(initialSelectedCuts),
  ])

  // The cuts filter uses the full list of selected cuts for its state,
  // but we need the categories and skus for JustCook, our url, and the filter tags ui.
  const { selectedGroups, selectedItems } = React.useMemo(
    () => getGroupsAndItemsFromSelections(selectedCutIds),
    [getGroupsAndItemsFromSelections, selectedCutIds]
  )

  // update the url when the filter state changes
  React.useEffect(() => {
    replaceUrl(
      buildUrl(
        selectedGroups.filter((c) => c !== 'nextBox'),
        selectedItems
      )
    )
  }, [selectedGroups, selectedItems])

  const uniqueSelections = {
    categories: selectedGroups,
    cuts: selectedItems,
  }

  const selectedTags = (): CutId[] => {
    // We filter out the nextBox items when the 'select all' is checked for that section to avoid unnecessary tags.
    const newSelectedItems = selectedItems.filter((item) => {
      return !(
        selectedGroups.includes('nextBox') &&
        nextBoxItems.itemIds.includes(item)
      )
    })
    return newSelectedItems
  }

  const tagSelections = {
    selectedGroups: selectedGroups,
    selectedTags: selectedTags(),
  }

  return {
    selectedCutIds,
    setSelectedCutIds,
    cutsGroups: cutsGroups,
    uniqueSelections,
    tagSelections,
  }
}

export default useBrowseRecipesFilterState
