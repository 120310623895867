import { Lockup, Text } from '@butcherbox/freezer'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'

export const BrowseRecipesPageHeader = () => (
  <Lockup data-cy={TEST_ID.ACCOUNT_BROWSE_RECIPES_HEADER}>
    <Text color="slate" component="span" variant="Eyebrow">
      Dinner is served!
    </Text>
    <Text color="slate" component="h1" variant="DisplayOne">
      Explore Recipes & Tips
    </Text>
  </Lockup>
)
