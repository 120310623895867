import React from 'react'
import {
  CutId,
  UICutsGroup,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import { TagListProps } from './TagList.types'
import TagListUI from './TagList.ui'

const returnFilterMap = (itemGroups: UICutsGroup[]) => {
  const filterCutsMap = {}
  for (const group of itemGroups) {
    filterCutsMap[group.group.id] = group.group.label
    for (const item of group.items) {
      filterCutsMap[item.id] = item.label
    }
  }
  return filterCutsMap
}

const TagList = ({
  itemGroups,
  selectedItems,
  onApplyFilters,
  tagSelections,
}: TagListProps) => {
  const filterCutsMap = React.useMemo(() => returnFilterMap(itemGroups), [
    itemGroups,
  ])
  const { selectedGroups, selectedTags } = tagSelections

  const removeTag = (cutId: CutId | string) => {
    // if the cutId is a string then it is a group and we need to remove all items in that group.items
    const group = itemGroups.find(({ group }) => group.id === cutId)
    if (group) {
      const newSelectedItems = selectedItems.filter(
        (id) => !group.items.map(({ id }) => id).includes(id)
      )
      onApplyFilters(newSelectedItems)
      // otherwise we are removing a single item
    } else {
      const newSelectedItems = selectedItems.filter((id) => id !== cutId)
      onApplyFilters(newSelectedItems)
    }
  }

  const clearTags = () => {
    onApplyFilters([])
  }

  return (
    <TagListUI
      filterCutsMap={filterCutsMap}
      handleClearAllClick={clearTags}
      handleTagClick={removeTag}
      selectedItems={[...selectedGroups, ...selectedTags]}
    />
  )
}

export default TagList
