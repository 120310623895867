import {
  ItemGroup,
  ItemId as ItemIdType,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'

/**
 * @param itemGroups
 * @param summaryGroups
 * use `summaryGroups` to identify groups whose items "actually" belong to another group
 * but who also, secondarily, belong to the summary group.
 */
const itemGroupSelectors = <
  GroupId extends ItemIdType,
  ItemId extends ItemIdType
>(
  itemGroups: ItemGroup<GroupId, ItemId>[],
  summaryGroups: GroupId[] = []
) => {
  const getGroupsAndItemsFromSelections = (selectedItemIds: ItemId[]) => {
    const selectedGroups: GroupId[] = []
    const selectedItems: ItemId[] = []
    itemGroups.forEach(({ group, itemIds }) => {
      if (itemIds.every((id) => selectedItemIds.includes(id))) {
        selectedGroups.push(group.id)
      } else if (!summaryGroups.includes(group.id)) {
        const selectedItemsInGroup = itemIds.filter((id) =>
          selectedItemIds.includes(id)
        )
        selectedItems.push(...selectedItemsInGroup)
      }
    })
    return { selectedGroups, selectedItems }
  }

  const getSelectionsFromGroupsAndItems = ({
    groupIds = [],
    itemIds = [],
  }: {
    groupIds: GroupId[]
    itemIds: ItemId[]
  }) =>
    groupIds
      .map(
        (groupId) =>
          itemGroups.find(({ group }) => group.id === groupId)?.itemIds
      )
      .flat()
      .concat(itemIds)

  return {
    getGroupsAndItemsFromSelections,
    getSelectionsFromGroupsAndItems,
  }
}

export default itemGroupSelectors
