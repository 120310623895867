import { Box, Button, Drawer, Text } from '@butcherbox/freezer'
import React, { useState } from 'react'
import { CloseButton } from '~/components/CartDrawer/components/NextBox/CloseButton'
import { TEST_ID } from '~/constants/cypress'
import { CutsFilterProps } from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import {
  ButtonsWrapper,
  CutsFilterContents,
  CutsFilterHeader,
  CutsFilterSection,
} from '~/routes/AccountBrowseRecipes/CutsFilter/CutsFilter.css'
import MultiSelectAccordionList from '~/routes/AccountBrowseRecipes/MultiSelectAccordionList/MultiSelectAccordionList'

const CutsFilter = ({
  onApplyChanges,
  isDrawerOpen = false,
  onClose,
  ...props
}: CutsFilterProps) => {
  const [selectedItemIds, setSelectedItemIds] = useState(props.selectedItemIds)

  const onSelectItem = (item, isChecked) => {
    const selectedGroup = props.itemGroups.find(
      (group) => group.group.id === item.id
    )
    if (selectedGroup) {
      const idsInGroup = selectedGroup.itemIds
      if (isChecked) {
        setSelectedItemIds([...new Set([...idsInGroup, ...selectedItemIds])])
      } else {
        setSelectedItemIds(
          selectedItemIds.filter((id) => !idsInGroup.includes(id))
        )
      }
    } else {
      setSelectedItemIds(
        !selectedItemIds.includes(item.id)
          ? [...selectedItemIds, item.id]
          : selectedItemIds.filter((i) => i !== item.id)
      )
    }
  }

  const onClickApply = () => {
    onApplyChanges(selectedItemIds)
    onClose()
  }

  const onClickClear = () => setSelectedItemIds([])

  return (
    <Drawer
      isDismissable
      isOpen={isDrawerOpen}
      onClose={onClose}
      placement={'left'}
      title="Filter by cut"
      zIndex="navigation"
    >
      <Box className={CutsFilterContents} data-cy={TEST_ID.CUTS_FILTER}>
        <Box className={CutsFilterHeader}>
          <Text variant="H3Bold">Filter by cut</Text>
          <CloseButton
            data-cy={TEST_ID.CUTS_FILTER_CLOSE_BUTTON}
            onClick={onClose}
          />
        </Box>

        <MultiSelectAccordionList
          className={CutsFilterSection}
          itemGroups={props.itemGroups}
          onSelectItem={onSelectItem}
          selectedItemIds={selectedItemIds}
        />

        {/* BUTTONS */}
        <Box className={ButtonsWrapper}>
          <Button
            data-cy={TEST_ID.CUTS_FILTER_CLEAR_BUTTON}
            onClick={onClickClear}
            variant="secondary"
            width="fluid"
          >
            Clear
          </Button>
          <Button
            data-cy={TEST_ID.CUTS_FILTER_APPLY_BUTTON}
            onClick={onClickApply}
            width="fluid"
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}

export default CutsFilter
