import { Box, Button, ButtonLink, Text } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import React from 'react'
import { BypassBlock, BypassBlockTarget } from '~/components/BypassBlock'
import RecipeGrid from '~/components/RecipeList/RecipeGrid'
import RecipesEmptyState from '~/components/RecipesEmptyState/RecipesEmptyState'
import { StickyBottom } from '~/components/StickyBottom/StickyBottom'
import TagList from '~/components/TagList/TagList'
import { TEST_ID } from '~/constants/cypress'
import * as Styles from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.css'
import { AccountBrowseRecipesUIProps } from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import { BrowseRecipesPageHeader } from '~/routes/AccountBrowseRecipes/BrowseRecipesPageHeader'
import CutsFilter from '~/routes/AccountBrowseRecipes/CutsFilter/CutsFilter'

const AccountBrowseRecipesUI = ({
  cutsGroups,
  isLoading,
  selectedCutIds,
  onApplyFilters,
  recipes,
  tagSelections,
  totalRecipeResults,
}: AccountBrowseRecipesUIProps) => {
  const [isFilterOpen, setIsFilterOpen] = React.useState(false)
  const openFilter = () => setIsFilterOpen((prevState) => !prevState)

  return (
    <Box>
      {isFilterOpen && (
        <CutsFilter
          isDrawerOpen={isFilterOpen}
          itemGroups={cutsGroups}
          onApplyChanges={onApplyFilters}
          onClose={openFilter}
          selectedItemIds={selectedCutIds}
        />
      )}

      <Box className={Styles.PageHeaderAndFilter}>
        <Box className={Styles.ContentWidth}>
          <BrowseRecipesPageHeader />
        </Box>
      </Box>

      <Box className={Styles.PageContent}>
        <Box className={Styles.ContentWidth}>
          {/* FILTER BY CUT CTA ROW*/}
          <Box className={Styles.Filter}>
            <Box className={Styles.FilterButton}>
              <Button
                data-cy={TEST_ID.FILTER_BY_CUT_BUTTON}
                onClick={openFilter}
                variant="secondary"
              >
                Filter by Cut
              </Button>
              <Box aria-live="polite" role="region">
                <Text color="granite" variant="Subhead2">
                  {`${totalRecipeResults} RESULTS`}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box className={Styles.FilterTags}>
            {selectedCutIds.length > 0 && (
              <BypassBlock className={Styles.BypassBlockStyles} />
            )}
            <TagList
              itemGroups={cutsGroups}
              onApplyFilters={onApplyFilters}
              selectedItems={selectedCutIds}
              tagSelections={tagSelections}
            />
          </Box>

          {/* RECIPE LIST */}
          <BypassBlockTarget display={'flex'} justifyContent={'center'}>
            {!isLoading && totalRecipeResults <= 0 ? (
              <RecipesEmptyState
                buttonText={`Browse all recipes`}
                containerStyleProps={{ marginTop: '0px' }}
                message={`We’re sorry, it appears we do not have any recipes available for your selected cut(s).
                  Please try selecting another cut or browse all of our recipes.`}
                title={`No recipes available`}
                url={`/account/recipes?category=all`}
              />
            ) : (
              <Box className={Styles.RecipeGridWrap}>
                <RecipeGrid
                  cardWidth="large"
                  columns={{ desktop: 3, tablet: 2, mobile: 1 }}
                  gridGap={24}
                  recipes={recipes}
                  recipesLoading={isLoading}
                />
              </Box>
            )}
          </BypassBlockTarget>
        </Box>
      </Box>

      {totalRecipeResults > 0 && (
        <StickyBottom>
          <ButtonLink component={Link} to="/account/saved-recipes">
            View saved recipes
          </ButtonLink>
        </StickyBottom>
      )}
    </Box>
  )
}

export default AccountBrowseRecipesUI
