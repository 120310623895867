import React from 'react'
import { Box, IconClose, Text } from '@butcherbox/freezer'
import * as Styles from './Tag.css'

const TagUI = ({ title, onClose }) => {
  return (
    <Box
      aria-label={`remove ${title} filter`}
      className={Styles.Tag}
      component={'button'}
      data-testid="tag"
      onClick={onClose}
    >
      <Box className={Styles.Text} data-testid="tag-title">
        <Text id="tag-text" variant="CTA">
          {title}
        </Text>
      </Box>

      <Box aria-hidden="true">
        <IconClose
          customColor={{ base: 'slate' }}
          pixelSize={12}
          size="fixed"
        />
      </Box>
    </Box>
  )
}

export default TagUI
