import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Checkbox,
  rem,
  VisuallyHidden,
} from '@butcherbox/freezer'
import React from 'react'
import {
  AccordionItemProps,
  ItemId,
  ItemListProps,
  MultiSelectAccordionListProps,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import { TEST_ID } from '~/constants/cypress'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'

const ItemList = <G extends ItemId, I extends ItemId>({
  items,
  selectedItemIds,
  onSelectItem,
  group,
}: ItemListProps<G, I>) => {
  const itemIds = React.useMemo(
    () => items.filter(({ id }) => id !== group.id),
    [group.id, items]
  )

  return (
    <Box px={16}>
      {items.map((item) => {
        const isSelectAll = item.id === group.id

        const isGroupChecked =
          isSelectAll && itemIds.every(({ id }) => selectedItemIds.includes(id))

        const checked = isGroupChecked || selectedItemIds.includes(item.id)
        return (
          <>
            <Checkbox
              checked={checked}
              data-cy={`${TEST_ID.MULTI_SELECT_ACCORDION_ITEM}_${item.id}`}
              id={
                isSelectAll
                  ? `select-all-${group.id}`
                  : `${group.id}}-${item.id}`
              }
              key={`${item.id}`}
              label={item.label}
              onChange={() =>
                // pass in the new (post-select) state, which is the inverse of our current state
                onSelectItem(item, !checked)
              }
              variant="interface"
            />
            {isSelectAll && (
              <PanelBreak intensity="light" style={{ marginBottom: rem(4) }} />
            )}
          </>
        )
      })}
    </Box>
  )
}

const AccordionItem = <G extends ItemId, I extends ItemId>({
  group,
  isGroupOpen,
  items,
  selectedItemIds,
  onSelectItem,
}: AccordionItemProps<G, I>) => {
  const [isOpen, setIsOpen] = React.useState(isGroupOpen)
  return (
    <Box component="li" key={group.id}>
      <Accordion
        defaultOpen={isGroupOpen}
        id={`${group.id}`}
        onChange={setIsOpen}
      >
        <AccordionSummary data-cy={TEST_ID.MULTI_SELECT_ACCORDION_GROUP}>
          {group.label}
        </AccordionSummary>
        <AccordionDetails>
          <Box component="fieldset" hidden={!isOpen}>
            <VisuallyHidden>{group.label}</VisuallyHidden>
            <ItemList
              group={group}
              items={items}
              onSelectItem={onSelectItem}
              selectedItemIds={selectedItemIds}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const MultiSelectAccordionList = <G extends ItemId, I extends ItemId>({
  itemGroups,
  selectedItemIds = [],
  onSelectItem,
  ...boxProps
}: MultiSelectAccordionListProps<G, I> & BoxProps) => {
  const itemGroupsWithSelectAll = React.useMemo(() => {
    return itemGroups.map((group) => ({
      ...group,
      // add "select all" item but don't update the itemIds
      items: [{ id: group.group.id, label: 'Select All' }, ...group.items],
    }))
  }, [itemGroups])

  return (
    <Box component="ul" {...boxProps}>
      {itemGroupsWithSelectAll.map(({ group, items, itemIds }) => {
        const isGroupOpen = itemIds.some((id) => selectedItemIds.includes(id))

        return (
          <AccordionItem
            group={group}
            isGroupOpen={isGroupOpen}
            items={items}
            key={group.id}
            onSelectItem={onSelectItem}
            selectedItemIds={selectedItemIds}
          />
        )
      })}
    </Box>
  )
}

export default MultiSelectAccordionList
